import React , { Component } from 'react'
import { Link } from 'gatsby'

// Images 
import portfolioImg from '../../assets/images/portfolio/p10.jpg'
import portfolioImg2 from '../../assets/images/portfolio/p11.jpg'
import portfolioImg3 from '../../assets/images/portfolio/p12.jpg'
import portfolioImg4 from '../../assets/images/portfolio/p13.jpg'
import portfolioImg5 from '../../assets/images/portfolio/p14.jpg'
import portfolioImg6 from '../../assets/images/portfolio/p15.jpg'
import portfolioImg7 from '../../assets/images/portfolio/p16.jpg'
import portfolioImg8 from '../../assets/images/portfolio/p17.jpg'

class Portfolio extends Component {

    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabs_item");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByTagName("li");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("current", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        evt.currentTarget.className += "current";
    }

    render() {
        return (
            <section className="portfolio pt-100 pb-100">
                <div className="container">
                    <div className="portfolio-contents tab">
                        <ul className="tabs">
                            <li
                                className="current"
                                onClick={(e) => this.openTabSection(e, 'tab1')}
                                role='presentation'
                            >
                                All
                            </li>
                            
                            <li
                                onClick={(e) => this.openTabSection(e, 'tab2')}
                                role='presentation'
                            >
                                Business
                            </li>

                            <li
                                onClick={(e) => this.openTabSection(e, 'tab3')}
                                role='presentation'
                            >
                                Finance
                            </li>

                            <li
                                onClick={(e) => this.openTabSection(e, 'tab4')}
                                role='presentation'
                            >
                                Advertising
                            </li>

                            <li
                                onClick={(e) => this.openTabSection(e, 'tab5')}
                                role='presentation'
                            >
                                Marketing
                            </li>
                        </ul>
    
                        <div className="tab-content">
                            <div className="tabs_item show active" id="tab1">
                                <div className="portfolio-cards-area-4">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg2} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg3} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg4} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg5} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg6} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg7} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg8} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
    
                            <div className="tabs_item show active" id="tab2">
                                <div className="portfolio-cards-area-4">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg7} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg8} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg2} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg3} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg4} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg5} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg6} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
    
                            <div className="tabs_item show active" id="tab3">
                                <div className="portfolio-cards-area-4">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg2} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg3} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg4} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg5} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg6} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg7} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg8} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
    
                            <div className="tabs_item show active" id="tab4">
                                <div className="portfolio-cards-area-4">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg7} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg8} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg2} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg3} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg4} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg5} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg6} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
    
                            <div className="tabs_item show" id="tab5">
                                <div className="portfolio-cards-area-4">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg2} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg3} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg4} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg5} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg6} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg7} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <div className="portfolio-card-4">
                                                <img 
                                                    src={portfolioImg8} 
                                                    alt="portfolio" 
                                                />
                                                <div className="portfolio-card-4-text-area">
                                                    <h4><Link to="/portfolio-details">Customer Management Process</Link></h4>
                                                    <p>Fauci has said he believes all states should</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    
}

export default Portfolio
