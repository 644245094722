import React from 'react'
import Layout from '../components/_App/Layout'
import Seo from '../components/_App/Seo'
import NavbarTwo from '../components/_App/NavbarTwo'
import PageBanner from '../components/Common/PageBanner'
import Portfolio from '../components/Portfolio/Portfolio'
import Footer from '../components/_App/Footer'
import Copyright from '../components/_App/Copyright'

const PortfolioPage = () => {
    return (
        <Layout>
            <Seo title="Portfolio" />
            <NavbarTwo />

            <PageBanner
                pageTitle="Our Best Projects" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Our Projects" 
            />

            <Portfolio />

            <Footer />

            <Copyright />
        </Layout>
    )
}

export default PortfolioPage
